<template>
  <div class="collection">
    <TopBar :type="2" :pageTitle="'我的收藏'" />
    <ul class="list">
      <li class="info" v-for="item in collectList" :key="item.collect_id" @click="$router.push('/recordedInfo/'+item.course_id)">
        <img class="info-img" :src="item.course_image" alt="">
        <div class="info-content">
          <div class="info-title vertical-ellipsis">
            <span class="learn-title">{{item.course_name}}</span>
          </div>
          <div class="amount">
            <span class="num"><span class="unit">￥</span>{{item.course_price}}</span>
            <!-- <span class="pay">{{item.course_name}}人已购</span> -->
            <span class="heart" :class="item.collect?'blue':''" @click.stop="heartBtn(item.course_id)"></span>
          </div>
        </div>
      </li>
      <li class="nodata" v-if="collectList && collectList.length == 0">
        <img class="nodata-img" :src="require('@/assets/no-data.png')">
        <p class="nodata-text">当前无收藏</p>
        <router-link :to="'/index'" class="back-index">返回首页</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
export default {
  name: 'Collection',
  data(){
    return {
      collectList: null, //收藏列表
    }
  },
  components: {
    TopBar
  },
  mounted(){
    this.getCollectList();//获取收藏列表
  },
  methods: {
    //获取收藏列表
    getCollectList(){
      this.$axios.post(`/v1/personal/collectList`, {
        page: 1,
        pre_page: 10,
      }, {useLog: true}).then(res => {
        let data = res.data;

        for(let i=0; i< data.length; i++){
          data[i].collect = true;
        }

        this.collectList = data;
      });
    },
    //收藏按钮
    heartBtn(id){
      this.$axios.post(`/v1/course/detail/collect`, {id}, {useLog: true}).then(res => {
        if(res){
          for(let i=0; i<this.collectList.length; i++){
            if(this.collectList[i].course_id == id){
              this.collectList[i].collect = this.collectList[i].collect ? false : true;
            }
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.list{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  &::after{
    content: "";
    display:block;
    width: 28%;
  }
}
.info{
  width: 335px;
  background: #FFF;
  box-shadow: 0px 0px 12px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  margin-bottom: 24px;
  .info-img{
    width: 100%;
    height: 186px;
  }
  .info-content{
    padding: 10px 20px 20px;
  }
  .info-title{
    height: 68px;
    line-height: 34px;
    position: relative;
    font-size: 28px;
    color: #333;
  }
  .amount{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    position: relative;
    .num{
      font-size: 36px;
      color: #CD1B14;
    }
    .unit{
      font-size: 22px;
    }
    .pay{
      font-size: 12px;
      color: #999;
    }
    .heart{
      position: absolute;
      width: 50px;
      top: 0;
      right: 0;
      bottom: 0;
      background: url(~@/assets/heart-gery-ico.png) no-repeat center center;
      background-size: 34px auto;
      cursor: pointer;
      &.blue{
        background: url(~@/assets/heart-blue-ico.png) no-repeat center center;
        background-size: 34px auto;
      }
    }
  }
}
.nodata{
  width: 574px;
  padding-top: 200px;
  margin: 0 auto;
  text-align: center;
  .nodata-img{
    width: 100%;
    height: auto;
  }
  .nodata-text{
    font-size: 26px;
    color: #999;
    padding-top: 140px;
  }
  .back-index{
    display: inline-block;
    padding: 10px;
    margin: 48px auto 0;
    font-size: 28px;
    color: #999;
  }
}
</style>
